<template>
  <div class="patrol">
    <div class="patrol-center">
      <div class="patrol-center-map">
        <TitleBox :titlename="'视频详情'" />
        <div class="viobox">
          <video id="videoElement" :src="videourl" controls autoplay muted></video>
        </div>
        <!-- <img src="../../assets/homeimg/1223.png" alt=""> -->
      </div>
      <div class="patrol-center-list">
        <TitleBox :titlename="'视频列表'" />
        <div class="tabbox">
          <ul>
            <li>
              <p>视频名称</p>
              <p>日期</p>
              <!-- <p>大小</p> -->
            </li>
            <li v-for="(item,index) in collectionlist" :key="index" @click="videobtn(item)">
              <p>{{item.videoName}}</p>
              <p>{{item.createTime}}</p>
              <!-- <p>{{item.address}}</p> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import api from '../visualization/api'

import TitleBox from '../visualization/components/title/index.vue'
export default {
  props: {},
  components: {
    TitleBox
  },
  data () {
    return {
      tableData: [{
        date: '2022-05-02',
        name: '科教宣传',
        address: '11.2M'
      }],
      videourl: '',
      collectionlist: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    videobtn (item) {
      this.videourl = item.url
    },
    footerbtn (index) {
      this.footerAction = index
    },
    pagevideo () {
      const obj = {
        pageNo: 1,
        pageSize: 5

      }
      api.listThird(obj).then(res => {
        // console.log(res, 'res')
        this.collectionlist = res.data.records
      })
      // axios.post(this.$constant.zsqyUrl + '/zsqy/VideoAddress/findByVideoPage', obj,
      //   { headers: { database: '11NatureReserves' } }
      // )
      //   .then((res) => {
      //     console.log(res, 'this.tableData1')

      //   })
    },
    pagevideourl () {
      const obj = {
        pageNo: 1,
        pageSize: 5

      }
      api.listThird(obj).then(res => {
        // console.log(res, 'res')
        // this.collectionlist = res.data.records
        this.videourl = res.data.records[0].url
      })
    }
  },
  created () {
    this.pagevideo()
    this.pagevideourl()
    document.title = '科教宣传'
  }
}
</script>
<style lang="less" scoped>
ul li {
  margin: 0;
  padding: 0;
}
.patrol {
  width: 100%;
  height: 100%;
  background: url('../../assets/homeimg/bg.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  &-center {
    width: 90%;
    height: 75%;
    margin: 100px 0 1% 2%;
    background: url('../../assets/homeimg/bb.png') no-repeat;
    background-size: 100% 100%;
    margin-left: 5%;
    overflow: hidden;
    display: flex;
    &-map {
      width: 60%;
      height: 90%;
      margin: 2% 0 0 1%;
      .viobox {
        width: 100%;
        height: 90%;
        margin-top: 2%;
        video {
          object-fit: contain;
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
    &-list {
      width: 37%;
      height: 90%;
      margin: 2% 0 0 1.5%;
      .tabbox {
        width: 100%;
        height: 73%;
        margin-top: 15px;
        ul {
          width: 100%;
          height: 100%;
          overflow-y: auto;
          li {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: space-between;
            // align-items: center;
            line-height: 40px;
            p {
              width: 25%;
              color: #fff;
            }
          }
          // li:nth-child(2n) {
          //   background: RGBA(56, 74, 97, 0.2);
          // }
          li:hover {
            background: rgba(79, 119, 163, 0.4);
          }
        }
        /* 设置滚动条的样式 */
        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          background: RGBA(56, 74, 97, 0.2);
        }
        /* 滚动条滑块 */
        ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: RGBA(56, 74, 97, 0.6);
          -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
</style>
